import React from "react";
import SEO from "../components/seo";
import Layout from "../containers/layout/layout";
import Banner from '../containers/company/banner'
import PrivacyPolicy from '../containers/privacy-policy/content';

export default () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <Banner/>
    <PrivacyPolicy />
  </Layout>
);