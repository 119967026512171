import React from 'react';
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import Heading from '../../../components/shared/heading'
/* import Text from '../../../components/shared/text' */
import {PolicySectionWrap, SectionTitle, PolicyContent, PolicyContainer, PolicyWrapper} from './policy.stc'
 
const PrivacyPolicy = ({HeadingStyle, TextStyle}) => {
    const policyQueryData = useStaticQuery(graphql `
        query PolicyDataQuery {
            policydataJson(id: {eq: "policy-intro-content"}) {
                id
                title
                desc
                content
            }
        }      
    `);
    const {title, desc, content} = policyQueryData.policydataJson;
    return (
        <PolicySectionWrap>
            <PolicyContainer>
                <div className="row">
                    {(title || desc) && (
                        <div className="col-10 offset-1">
                            <SectionTitle>
                                {title && <Heading {...HeadingStyle}>{title}</Heading>}                      
                            </SectionTitle>
                        </div>
                    )}
                </div>
                <PolicyWrapper> 
                    <div className="row">
                        {content && (
                            <div className="col-5 offset-1">
                                <PolicyContent>
                                    <div dangerouslySetInnerHTML={{ __html: content }} />
                                </PolicyContent>
                            </div>
                             
                        )}
                    </div>
                </PolicyWrapper>
            </PolicyContainer>
        </PolicySectionWrap>
    )
}

PrivacyPolicy.propTypes = {
    HeadingStyle: PropTypes.object,
    TextStyle: PropTypes.object
}

PrivacyPolicy.defaultProps = {
    HeadingStyle: {
        fontSize: '40px',
        texttransform: 'capitalize',
        lineHeight: '55px',
        color: 'primary',
        fontweight: 600,
        mb: '30px',
        responsive: {
            medium: {
                fontSize: '24px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '25px',
                lineHeight: '35px'
            }
        }
    },
    TextStyle: {
        lineHeight: '26px'
    },
    ProjectTypeHeading: {
        as: 'h6',
        color: 'primary',
        fontSize: '12px',
        fontweight: 700,
        letterspacing: '2px',
        mb: '12px'
    },
    ProjectTypeText: {
        m: 0,
        fontSize: '12px',
        color: '#000000',
        letterspacing: '1px'
    }
}

export default PrivacyPolicy;